import { useStaticQuery, graphql } from "gatsby";
import React from "react";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Layout from "../components/layout";
import { GatsbyImage } from "gatsby-plugin-image";
import { GatsbySeo } from "gatsby-plugin-next-seo";
import { Helmet } from "react-helmet";
import Hero from "../components/hero";
import NewsletterBanner from "../components/newsletter-banner";
import parse from "html-react-parser";

const GetInvolvedPage = () => {
	const data = useStaticQuery(graphql`
		query {
			heroImg: wpMediaItem(title: { eq: "Get-Involved-Hero" }) {
				altText
				localFile {
					publicURL
					childImageSharp {
						original {
							height
							width
						}
						gatsbyImageData(
							formats: [AUTO, WEBP]
							quality: 100
							transformOptions: { cropFocus: CENTER, fit: CONTAIN }
							layout: CONSTRAINED
							placeholder: BLURRED
						)
					}
				}
			}
			allWpGetInvolvedCard(sort: { fields: dateGmt, order: DESC }) {
				nodes {
					getInvolvedFields {
						description
						image {
							altText
							localFile {
								childImageSharp {
									gatsbyImageData(
										formats: [AUTO, WEBP]
										quality: 100
										transformOptions: { cropFocus: CENTER, fit: CONTAIN }
										layout: CONSTRAINED
										placeholder: BLURRED
									)
								}
							}
						}
					}
				}
			}

			site {
				siteMetadata {
					siteUrl
				}
			}
		}
	`);

	const siteUrl = data.site.siteMetadata.siteUrl;

	const breadcrumb = {
		"@context": "http://schema.org",
		"@type": "BreadcrumbList",
		itemListElement: [
			{
				"@type": "ListItem",
				position: "1",
				name: "Home",
				item: {
					url: `${siteUrl}`,
					id: `${siteUrl}`,
				},
			},
			{
				"@type": "ListItem",
				position: "2",
				name: "Get Involved",
				item: {
					url: `${siteUrl}/get-involved`,
					id: `${siteUrl}/get-involved`,
				},
			},
		],
	};

	return (
		<>
			<Helmet>
				{" "}
				<script type="application/ld+json">{JSON.stringify(breadcrumb)}</script>
			</Helmet>

			<GatsbySeo
				title="Get Involved | The Ella Roberta Foundation"
				description="Whether it’s raising awareness, campaigning in your local area, or fundraising - everything is welcome. There are so many things you can do to help us campaign for clean air."
				language="en"
				openGraph={{
					type: "website",
					url: `${siteUrl}/get-involved`,
					title: "Get Involved | The Ella Roberta Foundation",
					description:
						"Whether it’s raising awareness, campaigning in your local area, or fundraising - everything is welcome. There are so many things you can do to help us campaign for clean air.",
					images: [
						{
							url: `${data.heroImg?.localFile.publicURL}`,
							width: `${data.heroImg?.localFile.childImageSharp.original.width}`,
							height: `${data.heroImg?.localFile.childImageSharp.original.height}`,
							alt: `${data.heroImg?.altText}`,
						},
					],
				}}
			/>

			<Layout>
				<div className="bg-white">
					<Hero
						title={<span>GET INVOLVED</span>}
						backgroundImage={
							data.heroImg?.localFile.childImageSharp.gatsbyImageData
						}
					/>

					<section className="py-5 py-lg-8">
						<Container>
							<Row className="justify-content-center  gx-lg-5">
								<Col className="">
									<h2 className="fs-1 text-primary pb-4">
										DO YOU WANT TO HELP?
									</h2>
									<p>
										Whether it’s raising awareness, campaigning in your local
										area, or{" "}
										<a
											href="https://www.justgiving.com/ellaroberta-familyfoundation"
											target="_blank"
											rel="noreferrer"
										>
											fundraising
										</a>
										- everything is welcome. There are so many things you can do
										to help us campaign for clean air.
									</p>
									<p>
										Drop us a line with your suggestion;{" "}
										<a href="mailto:media@ellaroberta.org">
											media@ellaroberta.org
										</a>
										.
									</p>
								</Col>
							</Row>
							<Row>
								<Col>
									<h3 className="my-5 text-center text-lg-start">
										AMAZING THINGS PEOPLE HAVE DONE FOR THE FOUNDATION SO FAR:
									</h3>
								</Col>
							</Row>

							<Row>
								{data.allWpGetInvolvedCard.nodes.map((campaign) => (
									<Col className="mb-5" lg={6} xl={4}>
										<div
											className="w-100 h-100  position-relative"
											style={{
												boxShadow: "0px 3px 85px #00000029",
												borderRadius: "5px",
												overflow: "hidden",
											}}
										>
											<GatsbyImage
												style={{ height: "309px" }}
												class="w-100"
												image={
													campaign.getInvolvedFields.image.localFile
														.childImageSharp.gatsbyImageData
												}
												alt={campaign.getInvolvedFields.image.altText}
											/>
											<div className="p-4">
												<p
													style={{ fontSize: "100%" }}
													className="text-center text-lg-start"
												>
													{parse(campaign.getInvolvedFields.description)}
												</p>
											</div>
										</div>
									</Col>
								))}
							</Row>
						</Container>
					</section>
					<NewsletterBanner />
				</div>
			</Layout>
		</>
	);
};

export default GetInvolvedPage;
